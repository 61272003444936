import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";

import { loading } from "../components/Loading";


import { useClusterCostsSummary } from "../api/clustercostssummary";
import AWSNodeTemplateConfiguration from "../components/AWSNodeTemplateConfiguration";
import AlibabaCloudNodeTemplateConfiguration from "../components/AlibabaCloudNodeTemplateConfiguration";


export default function NodeTemplateConfiguration() {
    const { clusterID } = useParams();

    const cluster = useClusterCostsSummary();
    const [cloudProvider, setCloudProvider] = useState("");

    useEffect(() => {
        (async () => {
            if (clusterID === "") {
                return;
            }
            const summary = await cluster.getClusterCostsSummary(clusterID || "")
            if (summary.code !== 200) {
                console.error("Failed to fetch cluster summary:", summary.message);
                return;
            }
            setCloudProvider(summary.data?.cloudProvider || "");
        })();
    }, [clusterID]);

    if (cloudProvider === "") {
        return <loading.RequestLoading />
    }

    if (cloudProvider === "aws") {

    }
    switch (cloudProvider) {
        case "aws":
            return <AWSNodeTemplateConfiguration />
        case "alibabacloud":
            return <AlibabaCloudNodeTemplateConfiguration />
        default:
            return <></>
    }
}
