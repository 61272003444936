import { Response } from "../type/common"
import { server } from "../config/props"
import { ClusterBilling } from "../type/clusterbilling"
import { useApiFetch } from "../utils/use-api-fetch"
import { useCallback, useMemo } from "react";

export function useCluster() {
  const requests = useApiFetch();

  const unregisterCluster = useCallback(async function unregisterCluster(clusterID: string): Promise<Response<string>> {
    return requests.del(`${server}/api/v1/clusters/${clusterID}`)
  }, [requests])

  const getUninstallSH = useCallback(async function getUninstallSH(clusterID: string, provider: string): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/uninstall/sh?provider=${provider}`)
  }, [requests])

  const getUpgradeSH = useCallback(async function getUpgradeSH(clusterID: string, provider: string): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/upgrade/sh?provider=${provider}`)
  }, [requests])

  const getRestoreSH = useCallback(async function getRestoreSH(clusterID: string, provider: string): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/restore/sh?provider=${provider}`)
  }, [requests])

  const getClusterBilling = useCallback(async function getClusterBilling(clusterID: string): Promise<Response<ClusterBilling>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/billing`)
  }, [requests])

  return useMemo(() => ({
    unregisterCluster,
    getUninstallSH,
    getClusterBilling,
    getRestoreSH,
    getUpgradeSH,
  }), [unregisterCluster, getUninstallSH, getClusterBilling, getRestoreSH, getUpgradeSH])
}
