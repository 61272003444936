interface ClusterRebalanceConfiguration {
    enable: boolean,
    enableGPUNodePool: boolean | null,
    ec2NodeClassSpec: EC2NodeClassSpec | null,
    generalNodePoolSpec: NodePoolSpec | null,
    gpuNodePoolSpec: NodePoolSpec | null,
    ecsGeneralNodePoolSpec: NodePoolSpec | null,
    ecsGPUNodePoolSpec: NodePoolSpec | null,
    ecsNodeClassSpec: ECSNodeClassSpec | null,
}

interface ECSNodeClassSpec {
    tags: {[key: string]: string} | null,
    imageSelectorTerms: ImageSelectorTerms[] | null,
    systemDisk: SystemDisk | null,

}

interface ImageSelectorTerms {
    alias: string | null,
    id: string | null,
}

interface SystemDisk {
    categories: string[] | null,
    size: number | null,
}

interface EC2NodeClassSpec {
    tags: {[key: string]: string} | null,
    blockDeviceMappings: BlockDeviceMapping[] | null,
}

interface BlockDeviceMapping {
    deviceName: string,
    ebs: BlockDevice,
}

interface BlockDevice {
    volumeSize: string,
    volumeType: string,
    encrypted: boolean,
}

interface NodePoolSpec {
    template: NodeClaimTemplate,
}

interface NodeClaimTemplate {
    spec: NodeClaimSpec,
}

interface NodeClaimSpec {
    requirements: NodeSelectorRequirementWithMinValues[],
    kubelet: KubeletConfiguration | null,
}

interface KubeletConfiguration {
    kubeReserved: {[key: string]: string} | null,
}

interface NodeSelectorRequirementWithMinValues {
    key: string,
    operator: string,
    values: string[],
}

interface ClusterRebalanceStatus {
    state: ClusterRebalanceState,
    lastComponentsActiveTime: string,
    message: string,
}

enum ClusterRebalanceState {
    ClusterRebalanceStateApplying = "Applying",
	ClusterRebalanceStateLaunchingReplacements = "LaunchingReplacements",
	ClusterRebalanceStateDraining = "Draining",
	ClusterRebalanceStateTerminating = "Terminating",
	ClusterRebalanceStateFailed = "Failed",
	ClusterRebalanceStateSuccess = "Success",
}

export type {
    ClusterRebalanceStatus, ClusterRebalanceConfiguration, NodePoolSpec,
    NodeClaimTemplate, NodeClaimSpec, NodeSelectorRequirementWithMinValues, SystemDisk,
    EC2NodeClassSpec, ECSNodeClassSpec, BlockDeviceMapping, BlockDevice, KubeletConfiguration };
export { ClusterRebalanceState };
