import { useCallback, useMemo } from "react";
import { server } from "../config/props";
import { Response } from "../type/common";
import { useApiFetch } from "../utils/use-api-fetch";

export function useAgentSH() {
    const requests = useApiFetch();

    const getAgentSH = useCallback(async function getAgentSH(provider: 'alibabacloud' | 'aws' = 'aws', cluster_name?: string): Promise<Response<string>> {
        // ?provider=[aws|alibabacloud] &cluster_name=XXXX (only alibabacloud need cluster name, aws doesn't need)
        let endpoint = `${server}/api/v1/agent/sh?provider=${provider}`;
        if (provider === 'alibabacloud') {
            endpoint += `&cluster_name=${cluster_name}`;
        }
        return requests.get(endpoint);
    }, [requests])

    return useMemo(() => ({
        getAgentSH
    }), [getAgentSH])
}
