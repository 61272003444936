import { memo, useState } from "react";
import { useCallback } from 'foxact/use-typescript-happy-callback';
import { useEffect } from 'foxact/use-abortable-effect';
import { useAgentSH } from "../api/agentsh";
import { Input, Modal, Radio, type RadioChangeEvent } from "antd";
import CodeBlock from "./CodeBlack";
import { useDebounceValue } from "@ant-design/pro-components";

interface ConnectClusterModalProps {
  addClusterModal: boolean;
  onCancel: () => void;
  footer: React.ReactNode;
}

export default memo(function ConnectClusterModal({ addClusterModal, onCancel, footer }: ConnectClusterModalProps) {


  const [cloud, setCloud] = useState<'aws' | 'alibabacloud'>('aws');

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setCloud(e.target.value);
  };

  return (
    <Modal
      title="Connect your kubernetes cluster"
      open={addClusterModal}
      onCancel={onCancel}
      footer={footer}
    >
      <p className="mb-2">
        <span className="font-bold">CloudPilot AI</span>'s read-only agent only has the access to your cloud resource data, and won't change your cluster configuration.
      </p>

      <div className="my-4">
        <Radio.Group onChange={onChange} value={cloud}>
          <Radio value="aws">AWS</Radio>
          <Radio value="alibabacloud">Alibaba Cloud</Radio>
          <Radio disabled>Google Cloud</Radio>
          <Radio disabled>Azure</Radio>
        </Radio.Group>
      </div>

      {cloud === 'aws' && <ConnectAWSCluser />}
      {cloud === 'alibabacloud' && <ConnectAlibabaCloudCluster />}
    </Modal>
  )
});

function ConnectAWSCluser() {
  const agentsh = useAgentSH();

  const [agentSH, setAgentSH] = useState("");
  useEffect(() => {
    async function fetchAgentSH() {
      const { code, message, data } =
        await agentsh.getAgentSH('aws');
      if (code !== 200) {
        console.error("Failed to fetch agent sh:", message);
        return;
      }
      setAgentSH(data!);
    }
    fetchAgentSH();
  }, []);

  return (
    <>
      <p className="font-bold mb-2">Open your terminal and run the following command</p>
      <p className="mb-2">Please make sure kubectl is installed before running the command.</p>
      <CodeBlock code={agentSH} />
    </>
  )
}

function ConnectAlibabaCloudCluster() {
  const [clusterName, setClusterName] = useState("");
  const debouncedClusterName = useDebounceValue(clusterName, 500);

  const agentsh = useAgentSH();

  const [agentSH, setAgentSH] = useState("");
  useEffect((signal) => {
    (async function fetchAgentSH() {
      const { code, message, data } =
        await agentsh.getAgentSH('alibabacloud', debouncedClusterName);
      if (code !== 200) {
        console.error("Failed to fetch agent sh:", message);
        return;
      }
      if (!signal.aborted) {
        setAgentSH(data!);
      }
    })();
  }, [agentsh, debouncedClusterName]);

  return (
    <>
      <p className="font-bold mb-1">Please fill in the cluster name first</p>
      <p className="mb-2">
        Please check your cluster using the
        {' '}
        <a href="https://csnew.console.aliyun.com/?/k8s/cluster/list#/k8s/cluster/list" className="text-blue-500 underline hover:underline" target="_blank" rel="noreferrer nofollow noopener">
          link
        </a>.
      </p>
      <Input value={clusterName} className="mb-4" onChange={useCallback(e => setClusterName(e.target.value), [])} />
      {debouncedClusterName !== '' && (
        <>
          <p className="font-bold mb-1">Open your terminal and run the following command</p>
          <p className="mb-2">Please make sure kubectl is installed before running the command.</p>
          <CodeBlock code={agentSH} />
        </>
      )}
    </>
  )
}
