import { useState } from "react";

import { Statistic } from "antd";
import { ProCard } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import { ClusterCostsSummary } from "../type/clustercostssummary";

const { Divider } = ProCard;

interface CostOverviewCardProps {
  summaries: ClusterCostsSummary[];
}

function RenderMonthlyCost(dollarMonthlyCost: number, rmbMonthlyCost: number) {
  if (dollarMonthlyCost === 0 && rmbMonthlyCost === 0) {
    return (
      <div>
        <label>$</label>
        <span>0</span>
      </div>
    );
  }

  return (
    <div>
      { dollarMonthlyCost !== 0 ?
        <div>
          <label>$</label>
          <span>{dollarMonthlyCost.toFixed(2)}</span>
        </div> : <></> }
      { rmbMonthlyCost !== 0 ?
        <div>
          <label>¥</label>
          <span>{rmbMonthlyCost.toFixed(2)}</span>
        </div> : <></> }
    </div>
  );
}

function RenderEstimateSavingSaving(dollarEstimateSaving: number, rmbEstimateSaving: number) {
  if (dollarEstimateSaving === 0 && rmbEstimateSaving === 0) {
    return (
      <div>
        <label>$</label>
        <span>0</span>
      </div>
    );
  }

  return (
    <div>
      { dollarEstimateSaving !== 0 ?
        <div>
          <label>$</label>
          <span>{dollarEstimateSaving.toFixed(2)}</span>
        </div> : <></> }
      { rmbEstimateSaving !== 0 ?
        <div>
          <label>¥</label>
          <span>{rmbEstimateSaving.toFixed(2)}</span>
        </div> : <></> }
    </div>
  );
}


export default function CostOverviewCard({summaries}: CostOverviewCardProps) {
  const [responsive, setResponsive] = useState(false);
  const clusters = summaries.length;  
  const vcpus = summaries.reduce((acc, summary) => acc + summary.cpuCores, 0);

  const dollarMonthlyCost = summaries.filter((summary) => !summary.region.startsWith("cn-")).reduce((acc, summary) => acc + summary.monthlyCost, 0);
  const dollarEstimateSaving = summaries.filter((summary) => !summary.region.startsWith("cn-")).reduce((acc, summary) => acc + summary.estimateMonthlySaving, 0);
  const rmbMonthlyCost = summaries.filter((summary) => summary.region.startsWith("cn-")).reduce((acc, summary) => acc + summary.monthlyCost, 0);
  const rmbEstimateSaving = summaries.filter((summary) => summary.region.startsWith("cn-")).reduce((acc, summary) => acc + summary.estimateMonthlySaving, 0);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <ProCard.Group
        title="Cost overview"
        direction={responsive ? "column" : "row"}
      >
        <ProCard>
          <Statistic title="Clusters" value={clusters} precision={0} />
        </ProCard>
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <ProCard>
          <Statistic
            title="Monthly Spend"
            valueRender={() => {
              return RenderMonthlyCost(dollarMonthlyCost, rmbMonthlyCost);
            }}
            precision={2}
          />
        </ProCard>
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <ProCard>
          <Statistic
            title="EST. Savings"
            valueRender={() => {
              return RenderEstimateSavingSaving(dollarEstimateSaving, rmbEstimateSaving);
            }}
            precision={2}
          />
        </ProCard>
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <ProCard>
          <Statistic title="vCPU" value={vcpus} />
        </ProCard>
      </ProCard.Group>
    </RcResizeObserver>
  );
};
